<template>
  <div>
    <v-row justify="end">
      <template>
        <div class="my-2">
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="suppliers"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <!--Action -->
      <template v-slot:item.name="{ item }">
        <div>{{ item.first_name }} {{ item.last_name }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Supplier</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="ຊື່*"
                      required
                      v-model="supplier.first_name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="ນາມສະກຸນ"
                      required
                      v-model="supplier.last_name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      required
                      v-model="supplier.company"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="ເບີໂທ"
                      required
                      v-model="supplier.phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ທີ່ຢູ່"
                      required
                      v-model="supplier.address"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Supplier</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="ຊື່*"
                      required
                      v-model="edit_supplier.first_name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="ນາມສະກຸນ"
                      required
                      v-model="edit_supplier.last_name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="ບໍລິສັດ"
                      required
                      v-model="edit_supplier.company"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="ເບີໂທ"
                      required
                      v-model="edit_supplier.phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ທີ່ຢູ່"
                      required
                      v-model="edit_supplier.address"
                      :rules="addressRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="UpdateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>
    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      name: "",
      suppliers: [],
      catID: "",
      supplier: {},
      edit_supplier: {},
      headers: [
        {
          text: "ຊື່",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "ບໍລິສັດ",
          align: "start",
          value: "company",
          sortable: false,
        },
        {
          text: "ເບີໂທ",
          align: "start",
          value: "phone",
          sortable: false,
        },
        {
          text: "ທີ່ຢູ່",
          align: "start",
          value: "address",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      companyRules: [
        (v) => !!v || "Company is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 4 && v.length <= 11) ||
          "Phone number must be  4 - 11 numbers",
      ],
      addressRules: [
        (v) => !!v || "Address is required",
        (v) => (v && v.length >= 2) || "Must be less than 2 characters",
      ],

      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
    };
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },

    SubmitItem() {
      this.loading = true;
      this.$admin
        .post("add-supplier", this.supplier)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseModalAdd();
              this.supplier = {};
              this.fetchData();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          this.fetchData();
        });
    },

    CloseModalAdd() {
      this.village = {};
      this.reset();
      this.fetchData();
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_supplier = item;
      this.$store.commit("modalEdit_State", true);
    },

    UpdateItem() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin
          .post("update-supplier/" + this.edit_supplier.id, this.edit_supplier)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalEdit();
                this.edit_supplier = {};
                this.reset();
                this.fetchData();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.fetchData();
          });
      }
    },

    CloseModalEdit() {
      this.reset();
      this.cat_edit = {};
      this.fetchData();
      this.$store.commit("modalEdit_State", false);
    },

    fetchData() {
      this.TableLoading = true;
      this.$admin
        .get("list-supplier")
        .then((res) => {
          this.suppliers = res.data.suppliers;
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.catID = id;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("delete-supplier/" + this.catID)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.fetchData();
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.fetchData();
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
<style>
</style>